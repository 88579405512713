@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.cdnfonts.com/css/vogue');

body {
background-color:rgb(31, 31, 31)
/*background-image:url(./imgs/background1.jpg);
background-repeat:repeat;
background-size:35%;*/
}
.toolbar{
  width:90%;
}
h1{
  font-family: 'Vogue', sans-serif;
  font-size:7vw;
  line-height:6vw;
}
.location{
  height:40vh
}
h2{
  font-family: 'Vogue', sans-serif;
}
.socialIcon{
  fill:rgb(126, 126, 126)
}
.mainBody, .mainHeader{
  width:90%
}
.mainButton{
  transition:background 0.5s;
  border:2px solid #A58961
}
.mainButton:hover{
  background:none;
}
.footerBackground{
  height:500px;
  background-image: url(./imgs/background1.jpg);
  background-repeat:repeat;
  background-size:50%;
}
.footer{width:90%;height: 90%}
*{font-family: 'Abril Fatface', serif;}

.product{
  width:22.5%;
  height:200px;
  border: 2px solid rgb(87, 87, 87)
}
.bg-gold{background-color:#A58961;}
.text-gold:hover{color:#A58961}
.text-gold{color:#A58961}
.product:hover .productLogo{scale:125%}
.contactHalf{width:calc(50% - 24px)}
.formButton{border:2px solid #A58961}
.formButton:hover{
  background-color:transparent;
  border:2px solid #A58961;
}
